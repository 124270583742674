import React from "react";

interface HomePageProps {
  storeCode: string;
}

const HomePage: React.FC = () => {
    return null;
};

export default HomePage;
