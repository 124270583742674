import React from 'react';
import logoText from '../../assets/sehaa_plp_clp/LogoText.svg';
import logo from '../../assets/sehaa_plp_clp/chrwnx.svg';
import MagnifyingGlass from '../../assets/sehaa_plp_clp/MagnifyingGlass.svg';
import HandHeart from '../../assets/sehaa_plp_clp/HandHeart.svg';
import UserCircle from '../../assets/sehaa_plp_clp/UserCircle.svg';
import ShoppingCart from '../../assets/sehaa_plp_clp/ShoppingCart.svg';

const Navbar = () => {
  return (
    <div className="w-full bg-[#f05f6c] flex flex-col items-start justify-start p-3 md:p-5 text-left text-sm text-white">
      <div className="w-full flex flex-col md:flex-row items-center justify-start gap-4 md:gap-11">
  
        <div className="relative w-[160px] h-[40px] md:w-[212px] md:h-[56.1px] flex-shrink-0 overflow-hidden">
          <img className="absolute top-0 left-0 w-[12.22%] h-full" alt="Logo" src={logo} />
          <img
            className="absolute top-[8.91%] left-[18.59%] w-[81.42%] h-[75.04%]"
            alt="Logo Text"
            src={logoText}
          />
        </div>

        <div className="relative w-full h-10 md:h-12">
          <input
            className="w-full h-full rounded-lg bg-white bg-opacity-30 border-none outline-none p-2.5 pr-14 text-xs md:text-base text-gray-800"
            type="text"
            placeholder="What are you looking for?"
          />
          <img
            className="absolute right-4 top-1/2 transform -translate-y-1/2 w-5 h-5 pointer-events-none"
            alt="Search"
            src={MagnifyingGlass}
          />
        </div>

        <div className="flex flex-row items-center justify-start gap-2 md:gap-2.5 text-center text-xs">
          <div className="w-[60px] md:w-[81px] flex flex-col items-center justify-center gap-1 md:gap-2">
            <img className="w-5 h-5 md:w-6 md:h-6" alt="Wishlist" src={HandHeart} />
            <div className="leading-none">Wishlist</div>
          </div>
          <div className="w-[60px] md:w-[81px] flex flex-col items-center justify-center gap-1 md:gap-2">
            <img className="w-5 h-5 md:w-6 md:h-6" alt="Account" src={UserCircle} />
            <div className="leading-none">Account</div>
          </div>
          <div className="w-[60px] md:w-[81px] flex flex-col items-center justify-center gap-1 md:gap-2">
            <img className="w-5 h-5 md:w-6 md:h-6" alt="Cart" src={ShoppingCart} />
            <div className="leading-none">Cart</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
