import React, { createContext, useState, useContext } from 'react';
import { StoreView, Category, Product } from '../market/types/types';

interface StoreContextType {
  selectedStore: StoreView | null;
  setSelectedStore: (store: StoreView | null) => void;
  categories: Category[];
  setCategories: (categories: Category[]) => void;
  stores: StoreView[];
  setStores: (stores: StoreView[]) => void;
  selectedProduct: Product | null ;
  setSelectedProduct: (product: Product) => void;

}

const StoreContext = createContext<StoreContextType | undefined>(undefined);

export const StoreProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [stores, setStores] = useState<StoreView[]>([]);
  const [selectedStore, setSelectedStore] = useState<StoreView | null>(null);
  const [categories, setCategories] = useState<Category[]>([]);
  const [selectedProduct, setSelectedProduct] = useState<Product | null>(null);

  return (
    <StoreContext.Provider value={{ selectedStore, setSelectedStore, categories, setCategories, stores, setStores, selectedProduct, setSelectedProduct}}>
      {children}
    </StoreContext.Provider>
  );
};

export const useStore = () => {
  const context = useContext(StoreContext);
  if (context === undefined) {
    throw new Error('useStore must be used within a StoreProvider');
  }
  return context;
};