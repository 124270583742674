// import React from 'react';
// import { useParams } from 'react-router-dom';
// import ProductDescription from '../components/ProductDescription/ProductDescription';
// import ProductDetails from '../components/ProductDetails/ProductDetails';
// import ProductImages from '../components/ProductImages/ProductImages';
// import RelatedProducts from '../components/RelatedProducts/RelatedProducts';
// import { useStore } from '../../context/StoreContext';
// import { Product } from '../../market/types/types';

// const mapProductAttributes = (product: Product): Product => {
//   const customAttributesMap: { [key: string]: string } = {};

//   product.custom_attributes?.forEach(attribute => {
//     customAttributesMap[attribute.attribute_code] = attribute.value as string;
//   });

//   return {
//     ...product,
//     short_description: customAttributesMap['short_description'] || 'No description available',  // Extracting the description
//     brand: customAttributesMap['brand'] || 'No brand specified',                    // Extracting the brand
//   };
// };

// const ProductDetailsPage: React.FC = () => {
//   const { id } = useParams<{ id: string }>();
//   const { selectedStore, categories, selectedProduct } = useStore();
  
//   const storeCode = selectedStore?.code || '';
//   const categoryId = categories.length > 0 ? String(categories[0].id) : '';

//   // Map product attributes if product exists
//   const product = selectedProduct ? mapProductAttributes(selectedProduct) : null;

//   if (!id) {
//     return <p>Product ID is missing</p>; // Handle missing productId
//   }

//   return (
//     <div className='relative bg-white w-full overflow-auto text-center text-xs text-[#212529] font-sora min-h-[1080px] flex flex-col items-center justify-start gap-6 sm:gap-8 md:gap-10 lg:gap-12'>
//     <div className='w-full max-w-[1920px] flex flex-col items-start'>
//       <div className='w-full flex flex-col md:flex-row items-start justify-between px-4 sm:px-8 md:px-12 lg:px-24 box-border gap-4 sm:gap-6 md:gap-8 lg:gap-10'>
//         <div className='flex-1 max-w-full md:max-w-[50%]'>
//           <ProductImages product={product} storeCode={storeCode} categoryId={categoryId} />
//         </div>
//         <div className='flex-1 max-w-full md:max-w-[50%]'>
//           <ProductDetails product={product} />
//         </div>
//       </div>
//       <div className='w-full mt-6 sm:mt-8 md:mt-10 lg:mt-12 px-4 sm:px-8 md:px-12 lg:px-24 text-base'>
//         <ProductDescription />
//       </div>
//       <div className='w-full mt-6 sm:mt-8 md:mt-10 lg:mt-12 px-4 sm:px-8 md:px-12 lg:px-24 text-2xl'>
//         <RelatedProducts />
//       </div>
//     </div>
//   </div>
//   );
// };

// export default ProductDetailsPage;
// import React from 'react'

// const ProductDetailsPage = () => {
//   return (
//     <div className='relative bg-white w-full overflow-auto text-center text-xs text-[#212529] font-sora min-h-[1080px] flex flex-col items-center justify-start gap-6 sm:gap-8 md:gap-10 lg:gap-12'>
//     <div className='w-full max-w-[1920px] flex flex-col items-start'>
//       <div className='w-full flex flex-col md:flex-row items-start justify-between px-4 sm:px-8 md:px-12 lg:px-24 box-border gap-4 sm:gap-6 md:gap-8 lg:gap-10'>
//         <div className='flex-1 max-w-full md:max-w-[50%]'>
//           <ProductImages product={product} storeCode={storeCode} categoryId={categoryId} />
//         </div>
//         <div className='flex-1 max-w-full md:max-w-[50%]'>
//           <ProductDetails product={product} />
//         </div>
//       </div>
//       <div className='w-full mt-6 sm:mt-8 md:mt-10 lg:mt-12 px-4 sm:px-8 md:px-12 lg:px-24 text-base'>
//         <ProductDescription />
//       </div>
//       <div className='w-full mt-6 sm:mt-8 md:mt-10 lg:mt-12 px-4 sm:px-8 md:px-12 lg:px-24 text-2xl'>
//         <RelatedProducts />
//       </div>
//     </div>
//   )
// }

// export default ProductDetailsPage


import React from 'react'

const ProductDetailsPage = () => {
  return (
    <div>
      
    </div>
  )
}

export default ProductDetailsPage
