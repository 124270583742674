import React from "react";
import logo from "../../assets/sehaa_plp_clp/logo.png";
import Facebook from "../../assets/sehaa_plp_clp/FacebookLogo.svg";
import LinkedIn from "../../assets/sehaa_plp_clp/LinkedinLogo.svg";
import Instagram from "../../assets/sehaa_plp_clp/InstagramLogo.svg";
import Twitter from "../../assets/sehaa_plp_clp/TwitterLogo.svg";
import WhatsApp from "../../assets/sehaa_plp_clp/WhatsappLogo.svg";

const Footer = () => {
  return (
    <div className="w-full">
      <div className="flex flex-col justify-start items-start p-5 sm:p-10">
        <div className="flex flex-col lg:flex-row justify-start items-start w-full gap-10 lg:gap-10 lg:px-10">
          {/* Column 1 */}
          <div className="flex flex-col flex-1 gap-4 sm:gap-8">
            <img
              className="w-[200px] h-[64px] sm:w-[225px] sm:h-[72px] object-cover mix-blend-multiply"
              alt="Logo"
              src={logo}
            />
            <div className="flex flex-col gap-2">
              <p className="leading-6 text-sm sm:text-base">
                Neoshine Healthcare Alliance FZ LLC, Warehouse No.10, Gate 2,
                Red Crescent Warehouse, 6th St, Al Quoz - Dubai, United Arab
                Emirates
              </p>
              <p className="leading-6 text-sm sm:text-base">
                info@sehaaonline.com
              </p>
              <div className="flex items-center gap-2">
                <img
                  className="w-4 h-4"
                  alt="Email"
                  src="EnvelopeSimple.svg"
                />
                <span className="underline leading-6 text-sm sm:text-base">
                  info@sehaaonline.com
                </span>
              </div>
              <div className="flex items-center gap-2">
                <img className="w-4 h-4" alt="Phone" src="Phone.svg" />
                <span className="underline leading-6 text-sm sm:text-base">
                  +971 4 588 7100
                </span>
              </div>
              <div className="flex items-center gap-2">
                <img className="w-4 h-4" alt="WhatsApp" src={WhatsApp} />
                <span className="underline leading-6 text-sm sm:text-base">
                  +971 58 597 9509
                </span>
              </div>
            </div>
          </div>

          {/* Column 2 */}
          <div className="flex flex-col flex-1 gap-2 items-start sm:ml-20">
            <div className="font-semibold text-lg leading-6">
              Shop by Category
            </div>
            <div className="leading-6 text-sm sm:text-base">Bedroom Safety</div>
            <div className="leading-6 text-sm sm:text-base">Bathroom Safety</div>
            <div className="leading-6 text-sm sm:text-base">Mobility Aids</div>
            <div className="leading-6 text-sm sm:text-base">
              Orthopedic Supports
            </div>
            <div className="leading-6 text-sm sm:text-base">
              Compression Garments
            </div>
            <div className="leading-6 text-sm sm:text-base">
              Respiratory Care
            </div>
            <div className="leading-6 text-sm sm:text-base">
              Active Daily Living
            </div>
            <div className="leading-6 text-sm sm:text-base">Used Products</div>
            <div className="leading-6 text-sm sm:text-base">Rent Now</div>
          </div>

          {/* Column 3 */}
          <div className="flex flex-col items-start justify-start flex-1 gap-2">
            <div className="font-semibold text-lg leading-6">
              Sehaa Online Info
            </div>
            <div className="leading-6 text-sm sm:text-base">About Us</div>
            <div className="leading-6 text-sm sm:text-base">
              Sehaa Online Blog
            </div>
            <div className="leading-6 text-sm sm:text-base">Terms of Service</div>
            <div className="leading-6 text-sm sm:text-base">Privacy Policy</div>
            <div className="leading-6 text-sm sm:text-base">Job Openings</div>
          </div>

          {/* Column 4 */}
          <div className="flex flex-col items-start justify-start flex-1 gap-2">
            <div className="font-semibold text-lg leading-6">
              Sehaa Online Business
            </div>
            <div className="leading-6 text-sm sm:text-base">
              Sell your products on Sehaa Online
            </div>
            <div className="leading-6 text-sm sm:text-base">
              Seller Terms and Conditions
            </div>
            <div className="leading-6 text-sm sm:text-base">Seller FAQs</div>
            <div className="leading-6 text-sm sm:text-base">Affiliates</div>
          </div>

          {/* Column 5 */}
          <div className="flex flex-col flex-1 gap-4 sm:gap-8">
            <div className="flex flex-col gap-2">
              <div className="flex flex-col items-start justify-start gap-2">
                <div className="font-semibold text-lg leading-6">
                  Customer Support
                </div>
                <div className="leading-6 text-sm sm:text-base">Buyer FAQs</div>
                <div className="leading-6 text-sm sm:text-base">
                  Delivery Policy
                </div>
                <div className="leading-6 text-sm sm:text-base">
                  Return and Refund Policy
                </div>
                <div className="leading-6 text-sm sm:text-base">
                  Cash on Delivery
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-2 text-lg">
              <div className="font-semibold leading-6">Follow Us</div>
              <div className="flex gap-4">
                <img className="w-8 h-8" alt="Facebook" src={Facebook} />
                <img className="w-8 h-8" alt="Instagram" src={Instagram} />
                <img className="w-8 h-8" alt="LinkedIn" src={LinkedIn} />
                <img className="w-8 h-8" alt="Twitter" src={Twitter} />
                <img className="w-8 h-8" alt="WhatsApp" src={WhatsApp} />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-white border-t border-gray-200 p-5">
        <p className="text-xs text-gray-500 text-center">
          © 2020 Sehaaonline Healthcare Store. All Rights Reserved. Sehaaonline
          is a website operated by NeoShine Healthcare Alliance FZ LLC...
        </p>
      </div>
    </div>
  );
};

export default Footer;
