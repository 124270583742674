import React, { useEffect } from "react";
import flags from "../../assets/sehaa_plp_clp/flags.svg";
import WhatsApp from "../../assets/sehaa_plp_clp/WhatsappLogo.svg";
import EnvelopeSimple from "../../assets/sehaa_plp_clp/EnvelopeSimple.svg";
import { StoreView } from "../../types/types";
import { useStore } from "../../../context/StoreContext";
import { fetchStores, fetchCategories } from "../../api/MarketApiClient";

const Header: React.FC = () => {
  const { stores, selectedStore, setSelectedStore, setCategories, setStores } =
    useStore();

  useEffect(() => {
    const initializeStores = async () => {
      try {
        const storesData = await fetchStores();
        setStores(storesData);

        // Set default store to 'Sehaaonline UAE'
        const defaultStore =
          storesData.find(
            (store: StoreView) => store.name === "Sehaaonline UAE"
          ) || storesData[0];
        setSelectedStore(defaultStore);

        // Fetch categories for the default store
        const categories = await fetchCategories(defaultStore.code);
        setCategories(categories.children_data);
      } catch (error) {
        console.error("Error fetching stores:", error);
      }
    };

    initializeStores();
  }, [setSelectedStore, setCategories]);

  const handleStoreChange = async (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const storeId = Number(event.target.value);
    if (selectedStore?.id != storeId) {
      const selected =
        stores.find((store: StoreView) => store.id === storeId) || null;
      setSelectedStore(selected);

      // Fetch categories for the new store
      const categories = await fetchCategories(selected?.code || "");
      setCategories(categories.children_data);
    }
  };

  return (
    <div className="w-full flex flex-col items-start justify-start">
      <div className="w-full border border-gray-200 flex flex-col items-start justify-start p-4 md:px-10 lg:px-20">
        <div className="w-full flex flex-col sm:flex-row items-center justify-between gap-4 sm:gap-0 px-5">
          <div className="flex flex-row items-center justify-start gap-1">
            <img className="w-5 h-5" alt="Flags" src={flags} />
            <select
              value={selectedStore?.id || ""}
              onChange={handleStoreChange}
              className="store-select p-2 border border-gray-300 rounded-md text-xs sm:text-sm md:text-base"
            >
              {stores.map((store: StoreView) => (
                <option key={store.id} value={store.id}>
                  {store.name}
                </option>
              ))}
            </select>
          </div>
          <div className="flex flex-1 flex-col sm:flex-row items-start sm:items-center justify-end gap-2 sm:gap-2.5 text-xs sm:text-sm md:text-base">
            <div>9am to 9pm / 7 Days a week</div>
            <div className="hidden sm:block w-1.5 h-1.5 rounded-full bg-gray-300"></div>
            <div className="flex flex-row items-center gap-1">
              <img className="w-4 h-4" alt="WhatsApp" src={WhatsApp} />
              <div className="underline">+971 58 597 9509</div>
            </div>
            <div className="hidden sm:block w-1.5 h-1.5 rounded-full bg-gray-300"></div>
            <div className="flex flex-row items-center gap-1">
              <img className="w-4 h-4" alt="Email" src={EnvelopeSimple} />
              <div className="underline">info@sehaaonline.com</div>
            </div>
            <div className="hidden sm:block w-1.5 h-1.5 rounded-full bg-gray-300"></div>
            <div className="underline">Track Order</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
