import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import HomeSidebar from "../components/HomeSidebar/Sidebar";
import Cards from "../components/Cards/Cards";
import { Product, Category } from "../types/types";
import { useStore } from "../../context/StoreContext";
import { fetchProducts } from "../api/MarketApiClient";

const ProductListingPage: React.FC = () => {
  const { categoryName } = useParams<{ categoryName: string }>();
  const { selectedStore, categories } = useStore();
  const [products, setProducts] = useState<Product[]>([]);
  const [currentCategory, setCurrentCategory] = useState<Category | null>(null);

  useEffect(() => {
    if (!selectedStore || !categories.length || !categoryName) return;

    const category = categories.find(
      (cat: Category) =>
        cat.name.toLowerCase().replace(/\s+/g, "-") === categoryName
    );

    if (category) {
      setCurrentCategory(category);
      initializeProducts(category.id);
    }
  }, [selectedStore, categories, categoryName]);

  const initializeProducts = async (categoryId: number) => {
    try {
      const products = await fetchProducts(
        selectedStore?.code || "",
        categoryId.toString()
      );
      setProducts(products);
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  if (!selectedStore) return <div>Please select a store</div>;

  return (
    <div className="sehaa-clp">
      <div className="section flex flex-col md:flex-row items-start justify-start gap-4 md:gap-10 lg:gap-12 px-4 md:px-8 lg:px-20 text-left text-xs md:text-sm lg:text-base">
        <HomeSidebar />
        <Cards products={products} category={currentCategory} storeCode={selectedStore.code} />
      </div>
    </div>
  );
};

export default ProductListingPage;
