import React, { useState } from 'react';
import ArrowCircleUp from '../../assets/sehaa_plp_clp/ArrowCircleDown.svg';
import ArrowCircleDown from '../../assets/sehaa_plp_clp/ArrowCircleUp.svg';

const Sidebar = () => {
  const [activeDropdown, setActiveDropdown] = useState<string | null>(null);
  const [selectedOptions, setSelectedOptions] = useState<{ [key: string]: boolean }>({
    option1: false,
    option2: false,
    option3: false
  });

  const toggleDropdown = (category: string) => {
    setActiveDropdown(activeDropdown === category ? null : category);
  };

  const handleCheckboxChange = (option: string) => {
    setSelectedOptions((prevSelectedOptions) => ({
      ...prevSelectedOptions,
      [option]: !prevSelectedOptions[option]
    }));
  };

  return (
    <div className="w-full md:w-[300px] lg:w-[340px] shadow-[0_0_34px_rgba(0,0,0,0.1)] rounded-[20px] bg-white flex flex-col items-start justify-start">
      {/* Header */}
      <div className="w-full border-b border-gray-200 flex flex-row items-center justify-start p-4 md:p-5 text-[14px]">
        <div className="font-semibold">Shopping Options</div>
      </div>

      {/* Category Dropdown */}
      <div
        className={`w-full border-b border-gray-200 flex flex-row items-center justify-between p-4 md:p-5 cursor-pointer ${activeDropdown === 'category' ? 'active' : ''}`}
        onClick={() => toggleDropdown('category')}
      >
        <div className="font-bold leading-[150%]">Category</div>
        <img
          className={`w-[22px] h-[22px] ${activeDropdown === 'category' ? 'rotate-180' : ''}`}
          alt="Toggle Dropdown"
          src={ArrowCircleUp}
        />
      </div>
      {activeDropdown === 'category' && (
        <div className="w-full border-b border-gray-200 flex flex-col items-start justify-start p-4 md:p-5 gap-2">
          {['option1', 'option2', 'option3'].map((option, index) => (
            <label key={index} className="flex items-center gap-2 relative cursor-pointer">
              <input
                type="checkbox"
                id={option}
                checked={selectedOptions[option]}
                onChange={() => handleCheckboxChange(option)}
                className="hidden" // Hide the default checkbox
              />
              <div
                className={`w-4 h-4 border-2 border-pink-500 rounded flex items-center justify-center ${
                  selectedOptions[option] ? 'bg-pink-500' : 'bg-transparent'
                }`}
              >
                {selectedOptions[option] && (
                  <svg
                    className="w-3 h-3 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" d="M5 13l4 4L19 7" />
                  </svg>
                )}
              </div>
              <div className="font-bold">{`Option ${index + 1}`}</div>
            </label>
          ))}
        </div>
      )}

      {/* Color Dropdown */}
      <div
        className={`w-full border-b border-gray-200 flex flex-row items-center justify-between p-4 md:p-5 cursor-pointer ${activeDropdown === 'Color' ? 'active' : ''}`}
        onClick={() => toggleDropdown('Color')}
      >
        <div className="font-bold leading-[150%]">Color</div>
        <img
          className={`w-[22px] h-[22px] ${activeDropdown === 'Color' ? 'rotate-180' : ''}`}
          alt="Toggle Dropdown"
          src={ArrowCircleUp}
        />
      </div>
      {activeDropdown === 'Color' && (
        <div className="w-full border-b border-gray-200 flex flex-col items-start justify-start p-4 md:p-5 gap-2">
          {['option1', 'option2', 'option3'].map((option, index) => (
            <label key={index} className="flex items-center gap-2 relative cursor-pointer">
              <input
                type="checkbox"
                id={option}
                checked={selectedOptions[option]}
                onChange={() => handleCheckboxChange(option)}
                className="hidden" // Hide the default checkbox
              />
              <div
                className={`w-4 h-4 border-2 border-pink-500 rounded flex items-center justify-center ${
                  selectedOptions[option] ? 'bg-pink-500' : 'bg-transparent'
                }`}
              >
                {selectedOptions[option] && (
                  <svg
                    className="w-3 h-3 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" d="M5 13l4 4L19 7" />
                  </svg>
                )}
              </div>
              <div className="font-bold">{`Option ${index + 1}`}</div>
            </label>
          ))}
        </div>
      )}
    </div>
  );
};

export default Sidebar;
