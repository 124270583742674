import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import FilledStar from '../../assets/sehaa_plp_clp/Star.svg';
import pageLeft from '../../assets/sehaa_plp_clp/page-left.svg';
import pageRight from '../../assets/sehaa_plp_clp/page-right.svg';
import ProductCard from '../../assets/sehaa_plp_clp/image 9.png';
import CaretRight from '../../assets/sehaa_plp_clp/CaretRight.svg';
import CaretCircleDown from '../../assets/sehaa_plp_clp/CaretCircleDown.svg';
import { Product, Category } from '../../types/types';
import { fetchCategories, fetchProducts } from '../../api/MarketApiClient';
import { useStore } from '../../../context/StoreContext';

interface CardsProps {
  products: Product[];
  category: Category | null;
  storeCode: string;
}

const Cards: React.FC<CardsProps> = ({ products, category, storeCode }) => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [filter, setFilter] = useState<string>('All Products');
  const [categories, setCategories] = useState<Category[]>([]);
  const [subCategories, setSubCategories] = useState<Category[]>([]);
  const [filteredProducts, setFilteredProducts] = useState<Product[]>(products);
  const { setSelectedProduct } = useStore();

  const itemsPerPage = 16;
  const paginatedProducts = filteredProducts.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  useEffect(() => {
    if (!storeCode || !category) return;

    const initializeCategories = async () => {
      try {
        const categoriesData = await fetchCategories(storeCode);
        setCategories(categoriesData.children_data);

        if (category) {
          const selectedCategoryData = categoriesData.children_data.find(cat => cat.name === category.name);
          if (selectedCategoryData) {
            const thirdLevelCategories = selectedCategoryData.children_data?.filter(cat => cat.level === 3) || [];
            setSubCategories(thirdLevelCategories);

            // Fetch products for the selected category
            await fetchCategoryProducts(selectedCategoryData.id);
          }
        }
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };

    initializeCategories();
  }, [storeCode, category]);

  const fetchCategoryProducts = async (categoryId: number) => {
    try {
      const productsData = await fetchProducts(storeCode, categoryId.toString());
      setFilteredProducts(productsData);
      setCurrentPage(1); // Reset page when filtering changes
    } catch (error) {
      console.error('Error fetching category products:', error);
    }
  };

  const handleFilterChange = (filterType: string, categoryId?: number): void => {
    setFilter(filterType);
    setCurrentPage(1);

    if (filterType === 'All Products') {
      // Display all products of the selected category
      if (category) {
        fetchCategoryProducts(category.id);
      }
    } else if (categoryId) {
      // Display products for the selected sub-category
      fetchCategoryProducts(categoryId);
    }
  };

  const navigate = useNavigate();

  const handleClick = (product: Product) => {
    setSelectedProduct(product);
    navigate(`/product/${product.id}`);
  };

  return (
    <div className="cards-container">
      {/* Navbar */}
      <div className="w-full flex flex-col gap-2">
        <div className="flex flex-col gap-8 w-full">
          <div className="flex flex-col gap-2 w-full">
            <div className="relative font-semibold leading-none">{category?.name || 'All Products'}</div>
            <div className="flex items-center gap-1 text-xs text-gray-400">
              <div className="relative leading-none">Home</div>
              <img className="w-2.5 h-2.5 relative" alt="" src={CaretRight} />
              <div className="relative leading-none">{category?.name || 'All Products'}</div>
            </div>
          </div>
          <div className="w-full border-b border-gray-200 h-10 flex items-center gap-8 text-center text-sm cursor-pointer">
            <div className={`flex items-center justify-center pb-4 ${filter === 'All Products' ? 'border-b-2 border-pink-500' : ''}`} onClick={() => handleFilterChange('All Products')}>
              <div className="font-semibold leading-6">All Products</div>
            </div>
            {subCategories.map(cat => (
              <div key={cat.id} className={`flex items-center justify-center pb-4 ${filter === cat.name ? 'border-b-2 border-pink-500' : ''}`} onClick={() => handleFilterChange(cat.name, cat.id)}>
                <div className="leading-6">{cat.name}</div>
              </div>
            ))}
          </div>
        </div>
        <div className="w-full flex justify-between text-sm">
          <div>Items {currentPage * itemsPerPage - itemsPerPage + 1}-{Math.min(currentPage * itemsPerPage, filteredProducts.length)} of {filteredProducts.length}</div>
          <div className="flex items-center gap-2 text-xs">
            <div>Sort by</div>
            <div className="bg-gray-100 rounded-full flex items-center gap-1 py-1 px-2">
              <div>Name A to Z</div>
              <img className="w-3.5 h-3.5 relative" alt="" src={CaretCircleDown} />
            </div>
          </div>
        </div>
      </div>

      {/* Cards Grid */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-5 mt-2 mb-12 flex-grow">
        {paginatedProducts.map((product: Product) => (
          <div
            className="w-full sm:w-60 shadow-lg rounded-2xl bg-white flex flex-col items-center relative overflow-hidden"
            key={product.id}
            onClick={() => handleClick(product)}
          >
            <div className="w-[90%] h-32 mt-6 ml-2 relative overflow-hidden">
              <img
                className="w-full h-full object-contain block rounded-t-2xl"
                alt="Product"
                src={product.media_urls.thumbnail || ProductCard}
              />
            </div>
            <div className="w-full flex flex-col items-center justify-center p-2.5 gap-4 z-10">
              <div className="w-full flex flex-col items-start gap-1.5">
                <div className="text-lg font-semibold">{product.name}</div>
                <div className="w-full flex flex-row items-center gap-2 text-sm">
                  <div className="flex flex-row items-center gap-1">
                    {[...Array(5)].map((_, index) => (
                      <img key={index} className="w-3.5 h-3.5" alt="Star" src={FilledStar} />
                    ))}
                  </div>
                  <div>{product.reviews_count} Reviews</div>
                </div>
              </div>
              <div className="w-56 flex flex-row justify-between items-end text-xl">
                <b className="relative font-semibold">{product.special_price ? product.special_price : product.price}</b>
                {product.special_price && (
                  <b className="relative text-sm line-through text-gray-400">{product.price}</b>
                )}
              </div>
              <div className="w-full flex flex-row items-start text-xs">
                <div>Only {product.stock_count} left in stock - Order soon.</div>
              </div>
            </div>
            <div className="w-full bg-gray-100 h-[1px] z-20"></div>
            <div className="w-full rounded-lg flex items-center justify-center py-2 text-[#f05f6c] text-center z-30">
              <div className="font-semibold">Add to cart</div>
            </div>
            <div className="absolute top-0 left-0 bg-[#f05f6c] h-7 px-4 text-white text-xs flex items-center justify-center rounded-tl-2xl rounded-br-2xl z-40">
              Summer Deal
            </div>
          </div>
        ))}
      </div>

      {/* Pagination */}
      <div className="flex items-center justify-center py-5 bg-gray-100 mt-auto">
        <img className="w-8 h-8 relative opacity-50 cursor-pointer" alt="" src={pageLeft} onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))} />
        <div className="w-8 h-8 flex items-center justify-center bg-pink-500 rounded-md text-white relative">
          <div className="font-semibold">{currentPage}</div>
        </div>
        <img className="w-8 h-8 relative cursor-pointer" alt="" src={pageRight} onClick={() => setCurrentPage(prev => Math.min(prev + 1, Math.ceil(filteredProducts.length / itemsPerPage)))} />
      </div>
    </div>
  );
};

export default Cards;
