import axios from 'axios';
import { Category, Product, StoreView } from '../types/types';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:8000';
const IMAGE_BASE_URL = 'https://dev.sehaaonline.com/media/catalog/product';

const apiClient = axios.create({
  baseURL: API_BASE_URL,
});

export const fetchStores = async () : Promise<StoreView[]> => {
  const response = await apiClient.get('/market/store/storeviews');
  return response.data;
};

export const fetchCategories = async (storeCode: string) : Promise<Category> => {
  const response = await apiClient.get(`/market/store/${storeCode}/categories`);
  return response.data;
};

// export const fetchProducts = async (storeCode: string, categoryId: string): Promise<Product[]> => {
//   const response = await apiClient.get(`/market/store/${storeCode}/categories/${categoryId}/products`);

//   // Ensure response and products exist
//   const productsData = response.data?.products || [];
  
//   // If no products are returned, return an empty array
//   return productsData.map((product: any) => {
//     const baseUrl = 'https://dev.sehaaonline.com/media/catalog/product';

//     return {
//       ...product,
//       image_urls: {
//         thumbnail: baseUrl + product.image_urls.thumbnail,
//         small: baseUrl + product.image_urls.small,
//         large: baseUrl + product.image_urls.large,
//         file: product.image_urls.file.map((file: string) => baseUrl + file),
//       },
//     };
//   });
// };


// export const fetchProducts = async (storeCode: string, categoryId: string): Promise<Product[]> => {
//   try {
//     const response = await apiClient.get(`/market/store/${storeCode}/categories/${categoryId}/products`);
//     const baseUrl = 'https://dev.sehaaonline.com/media/catalog/product';

//     // Ensure products exist
//     if (!response.data.products) return [];

//     // Map products and handle cases where image_urls might be undefined
//     return response.data.products.map((product: any) => {
//       const imageUrls = product.image_urls || {};
//       return {
//         ...product,
//         image_urls: {
//           thumbnail: imageUrls.thumbnail ? `${baseUrl}${imageUrls.thumbnail}` : '',
//           small: imageUrls.small ? `${baseUrl}${imageUrls.small}` : '',
//           large: imageUrls.large ? `${baseUrl}${imageUrls.large}` : '',
//           file: imageUrls.file ? imageUrls.file.map((url: string) => `${baseUrl}${url}`) : [],
//         },
//       };
//     });
//   } catch (error) {
//     console.error('Error fetching products:', error);
//     throw error; // Re-throw error to be caught by caller
//   }
// };



export const fetchProducts = async (storeCode: string, categoryId: string): Promise<Product[]> => {
  try {
    const response = await apiClient.get(`/market/store/${storeCode}/categories/${categoryId}/products`);
    const baseUrl = 'https://dev.sehaaonline.com/media/catalog/product';

    // Ensure products exist
    if (!response.data[0].products) return [];

    // Map products and handle cases where media_urls might be undefined
    return response.data[0].products.map((product: any) => {
      const mediaUrls = product.media_urls || {};

      return {
        id: product.id || '', 
        name: product.name || '',
        price: product.price || 0, 
        special_price: product.special_price !== null ? product.special_price : null, 
        discount_amount: product.discount_amount || 0,
        price_with_tax: product.price_with_tax || 0,
        special_price_with_tax: product.special_price_with_tax !== null ? product.special_price_with_tax : null, 
        url_key: product.url_key || '',
        reviews_count: product.reviews_count || 0,
        average_rating: product.average_rating || 0, 
        stock_status: product.stock_status || false,
        stock_count: product.stock_count || 0, 
        media_urls: {
          thumbnail: mediaUrls.thumbnail ? `${baseUrl}${mediaUrls.thumbnail}` : '',
          small: mediaUrls.small ? `${baseUrl}${mediaUrls.small}` : '',
          large: mediaUrls.large ? `${baseUrl}${mediaUrls.large}` : '',
          file: mediaUrls.file ? mediaUrls.file.map((url: string) => `${baseUrl}${url}`) : [],
        },
      };
    });
  } catch (error) {
    console.error('Error fetching products:', error);
    throw error; 
  }
};